
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'ShopImageWithUpload',
	props: {
		src: {
			type: String,
			default: null
		},
		camera: {
			type: Boolean,
			default: true
		}
	}
})
