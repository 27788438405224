import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShopImageWithUpload = _resolveComponent("ShopImageWithUpload")!
  const _component_el_upload = _resolveComponent("el-upload")!

  return (_openBlock(), _createBlock(_component_el_upload, {
    class: "",
    action: "https://jsonplaceholder.typicode.com/posts/",
    "http-request": _ctx.onUploadComplete,
    "auto-upload": true,
    "show-file-list": false,
    "on-success": _ctx.handleAvatarSuccess,
    "before-upload": _ctx.beforeAvatarUpload,
    accept: 'image/*'
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ShopImageWithUpload, { src: _ctx.imageUrl }, null, 8, ["src"])
    ]),
    _: 1
  }, 8, ["http-request", "on-success", "before-upload"]))
}