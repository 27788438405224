import { defineStore } from 'pinia'
import { State } from '@/pinia/article/types'
import { ArticleData } from '@/api/article/article.type'
import { getArticleList } from '@/api/article/article.api'

export const useArticleStore = defineStore('article', {
	state: (): State => ({
		articles: []
	}),
	getters: {
		getterGetArticleById: (state: State) => (articleId: string) => {
			return state.articles.find(art => art._id === articleId)
		}
	},
	actions: {
		// old mutations
		updateArticleList(articles: ArticleData[]) {
			this.articles = articles
		},
		// old actions
		async actionGetAllArticle() {
			try {
				const { data: articlesResponse } = await getArticleList()
				this.updateArticleList(articlesResponse)
			} catch (error) {
				return Promise.reject(error)
			}
		},
	}
})