
import { defineComponent, PropType, ref, computed } from 'vue'
import { ElNotification } from 'element-plus'
import ShopImageWithUpload from '@/components/shop/ShopImageWithUpload.vue'

export default defineComponent({
	name: 'ShopUpload',
	components: { ShopImageWithUpload },
	props: {
		imageUrl: {
			type: String as PropType<string>,
			default: ''
		},
	},
	emits: ['update:imageUrl', 'update:phoneNo', 'update:imageFile'],
	setup(_, { emit }) {
		//
		const stepOneFormRef = ref<any>(null)
		const isReactNativeWebview = computed(() => window.ReactNativeWebView)
		const beforeAvatarUpload = (file: any) => {
			const isValidType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp'
			const isLimit10mb = file.size / 1024 / 1024 < 10

			if (!isValidType) {
				ElNotification({
					title: 'ผิดพลาด',
					message: 'รูปแบบไฟล์ไม่ถูกต้อง',
					type: 'error',
					showClose: !isReactNativeWebview.value
				})
			}
			if (!isLimit10mb) {
				ElNotification({
					title: 'ผิดพลาด',
					message: 'ขนาดรูปภาพต้องไม่เกิน 10mb',
					type: 'error',
					showClose: !isReactNativeWebview.value
				})				
			}
			return isValidType && isLimit10mb
		}
		const handleAvatarSuccess = (_: any, file: any) => {
			// imageUrl.value = URL.createObjectURL(file.raw)
			emit('update:imageUrl', URL.createObjectURL(file.raw))
		}

		const onUploadComplete = async (upload: any) => {
			emit('update:imageFile', upload.file)
		}

		return {
			stepOneFormRef,
			beforeAvatarUpload,
			handleAvatarSuccess,
			onUploadComplete
		}
	},
})
