import { AxiosResponse } from 'axios'
import axios from '../instance'
import { ArticleData } from './article.type'

export const getArticleList = async (): Promise<AxiosResponse<ArticleData[]>> => {
	try {
		const response = await axios.get('/article')
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}